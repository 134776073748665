<script setup>
import { Cloudinary } from "@cloudinary/url-gen";
import { ref, onMounted } from "vue";
import CldImage from "../components/CldImage.vue";
import ImageModal from "../components/ImageModal.vue";
import { AdvancedImage } from "@cloudinary/vue";

const cld = new Cloudinary({
  cloud: {
    cloudName: `${process.env.VUE_APP_CLOUDINARY_CLOUD_NAME}`,
  },
});

const photos = ref([]);
const loading = ref(true);
const selectedImageId = ref("");
const showModal = ref(false);

const getData = async (tag) => {
  const response = await fetch(
    `https://res.cloudinary.com/${process.env.VUE_APP_CLOUDINARY_CLOUD_NAME}/image/list/${tag}.json`
  );
  const data = await response.json();
  photos.value = data.resources;
  loading.value = false;
};
onMounted(() => {
  getData("Tekken8-1");
});

function showImageModal(imageUrl) {
  selectedImageId.value = imageUrl;
  showModal.value = true;
}
</script>




<template>
  <template v-if="loading">
    <p>Loading gallery</p>
  </template>
  <template v-if="photos.length !== 0">
    <div class="fotosContainer">
      <div v-for="(photo, idx) in photos" :key="idx" class="fotos">
        <div @click="showImageModal(photo.public_id)" class="imgContainer">
          <div class="shimmer animate"></div>
          <CldImage
            :publicId="photo.public_id"
            style="cursor: pointer; max-width: 100%; object-fit: scale-down"
          />
        </div>
      </div>
    </div>
  </template>

  <Teleport to="body">
    <!-- use the modal component, pass in the prop -->
    <image-modal :show="showModal" @close="showModal = false">
      <template #body>
        <AdvancedImage
          :cldImg="cld.image(selectedImageId)"
          style=""
          class="fotoClass"
        />
      </template> </image-modal
    >\
  </Teleport>
</template>

<style scoped>
.fotosContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  width: 100%;
}

.fotos {
  /* flex: 1; */
  width: 49%;
  display: flex;
}

.fotoClass {
  max-width: 90vh;
  max-height: 110vh;
}

.imgContainer {
  width: 100%;
  min-height: 300px;
  position: relative;
}

.shimmer {
  background: linear-gradient(90deg, #4b4b4b, #9f9f9f 50%, #4b4b4b);
  /* background-size: 300%;
  background-position-x: 100%;
  animation: shimmer 1s 10 linear; */
  border-radius: 3px;
  height: 90%;
  width: 90%;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.animate {
  animation: shimmer 3s;
  animation-iteration-count: 10;
  /* background: linear-gradient(to right, #e6e6e6 5%, #cccccc 25%, #e6e6e6 35%); */
  background-size: 1000px 100%;
}


@keyframes shimmer {
  from {
    background-position: -1000px 0;
  }
  to {
    background-position: 1000px 0;
  }
}


@media only screen and (max-width: 600px) {
  .fotos {
    width: 99%;
    display: flex;
  }

  .fotoClass {
    max-width: 95vw;
    max-height: 110vh;
  }

  .imgContainer {
    min-height: 20vh;
  }
}
</style>